<template>
  <div class="company-page">

    <div class="float-end">
      <router-link to="/company/admin/add" class="btn btn-sm btn-secondary">Add company</router-link>
    </div>
    <h1>Company management</h1>

    <div class="table-responsive">
      <table class="table">
        <thead class="table-light small">
        <tr>
          <th width="1%">ID</th>
          <th>Title</th>
          <th width="1%"></th>
        </tr>
        </thead>
        <tbody v-if="companyList?.length > 0">
          <tr v-for="companyData in companyList" :key="companyData.id">
            <td class="text-nowrap">{{ companyData.id }}</td>
            <td>{{ companyData.title }}</td>
            <td class="text-nowrap">
              <router-link :to="{path: '/company/admin/edit/' + companyData.id}" class="fa fa-pencil-square icon-fa"></router-link>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="100%" class="text-center">No records found</td>
          </tr>
        </tbody>
      </table>
    </div>

    <pager-nav :pager="companyPager" :route="'/company/admin'" @onPagerUpdate="onPagerUpdate"></pager-nav>

  </div>
</template>

<script>
import PagerNav from "@/components/PagerNav";

export default {
  components: {PagerNav},

  data: () => ({
    companyList: [],
    companyPager: {}
  }),

  async mounted() {

    if (!await this.checkManager())
      return;

    this.setBreadcrumbs();

    await this.doCompanyAdminIndex();
  },

  methods: {

    async onPagerUpdate() {

      await this.doCompanyAdminIndex();
    },

    async doCompanyAdminIndex() {

      let apiHolder = await this.sendApiGet(['api/company/admin/index', {
        page: this.$route.query?.page || 1
      }]);

      if (!apiHolder.isSuccess())
        return;

      if (apiHolder.data?.companyList)
        this.companyList = apiHolder.data.companyList;
      if (apiHolder.data?.companyPager)
        this.companyPager = apiHolder.data.companyPager;
    },

    setBreadcrumbs() {

      this.$emit("setBreadcrumbs", [
        ['Admin panel', '/admin'],
        'Company management'
      ]);
    }

  }

}
</script>

<style scoped>

</style>